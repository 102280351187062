import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import CountryRegionDropdowns from 'views/common/components/UI/CountryRegionDropdowns';
import { FormErrors } from 'views/common/components';
import { useTranslation } from 'next-i18next';

const BillingAddress = (props) => {
  const {
    creditCardInfo, setCreditCardInfo, countries, creditCardError,
  } = props;
  const { t } = useTranslation('fe_er_billing_address');
  const handleSelectChange = (value, type) => {
    creditCardInfo.billing_address[type] = value || { name: null, id: null };
    setCreditCardInfo({ ...creditCardInfo });
  };
  const countryArr = countries.map((country) => ({
    ...country,
    has_regions: true,
  }));

  return (
    <>
      <div>
        <Box mt={1.5}>
          <CountryRegionDropdowns
            handleSelectChange={handleSelectChange}
            data={{
              countries: countryArr,
              country: creditCardInfo.billing_address.country,
              region: creditCardInfo.billing_address.region,
              countryError:
                creditCardError && creditCardError.errors.country_id,
              regionError: creditCardError && creditCardError.errors.region_id,
            }}
            i18nDropdowns={{
              countryLabel: t('fe_er_billing_address:country'),
              regionLabel: t('fe_er_billing_address:state_province'),
            }}
          />
        </Box>

        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <TextField
              id="address"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:address')}
              variant="outlined"
              name="address"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.address || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.address = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.address,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="address" />
            )}
          </Grid>

          <Grid item sm={12} md={6}>
            <TextField
              id="address_2"
              fullWidth
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:address2')}
              variant="outlined"
              name="address_2"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.address_2 || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.address_2 = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <TextField
              id="city"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:city')}
              variant="outlined"
              name="city"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.city || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.city = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.city,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="city" />
            )}
          </Grid>

          <Grid item sm={12} md={6}>
            <TextField
              id="postal_code"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:postal_code')}
              variant="outlined"
              name="postal_code"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.postal_code || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.postal_code = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.postal_code,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors
                errorMsgs={creditCardError}
                errorField="postal_code"
              />
            )}
          </Grid>

          <Grid item sm={12} md={6}>
            <TextField
              id="first_name"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:first_name')}
              variant="outlined"
              name="first_name"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.first_name || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.first_name = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.first_name,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="first_name" />
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              id="last_name"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:last_name')}
              variant="outlined"
              name="last_name"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.last_name || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.last_name = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.last_name,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="last_name" />
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              id="email"
              fullWidth
              required
              color="secondary"
              type="text"
              label={t('fe_er_billing_address:email')}
              variant="outlined"
              name="email"
              autoComplete="new-password"
              value={creditCardInfo.billing_address.email || ''}
              onChange={(e) => {
                creditCardInfo.billing_address.email = e.target.value;
                setCreditCardInfo({ ...creditCardInfo });
              }}
              error={Boolean(
                creditCardError
                  && creditCardError.errors
                  && creditCardError.errors.email,
              )}
            />
            {creditCardError && creditCardError.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="email" />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BillingAddress;
