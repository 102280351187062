/* eslint-disable react/no-array-index-key */
/* eslint-disable default-case */
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import ScrollTo from 'lib/utils/ScrollTo';
import PaymentOption from './PaymentOption';

const PREFIX = 'PaymentsOptions';

const classes = {
  root: `${PREFIX}-root`,
  labelOption: `${PREFIX}-labelOption`,
  active: `${PREFIX}-active`,
  paymentName: `${PREFIX}-paymentName`,
  icons: `${PREFIX}-icons`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflow: 'hidden',
  },

  [`& .${classes.labelOption}`]: {
    backgroundColor: theme.palette.grey.A000,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1.4),
    },
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(1.5, 0),
    border: '1px solid transparent',
    alignItems: 'center',
    '&$active': {
      borderColor: theme.palette.grey[600],
    },
  },

  [`& .${classes.active}`]: {},

  [`& .${classes.paymentName}`]: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[800],
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  [`& .${classes.icons}`]: {
    '& img': {
      marginLeft: theme.spacing(1),
      height: theme.typography.pxToRem(24),
      [theme.breakpoints.down('md')]: {
        height: theme.typography.pxToRem(16),
      },
    },
  },
}));

const paymentTypes = [
  {
    id: 1,
    name: 'Credit or Debit Card',
    key: 'credit_card',
    icons: [
      'visa.svg',
      'master.svg',
      'discover.svg',
      'american_express.svg',
      'jcb.svg',
    ],
  },
  // {
  //   id: 2,
  //   name: "PayPal",
  //   key: "paypal",
  //   icons: ["paypal.svg"],
  // },
  // {
  //   id: 3,
  //   name: "Apple Pay",
  //   key: "applepal",
  //   icons: ["apple-pay.svg"],
  // },
  // {
  //   id: 4,
  //   name: "Google Pay",
  //   key: "googlepay",
  //   icons: ["google-pay.svg"],
  // },
];
/**
 *
 * @param {0} paymentOptions= {
//   creditCard: {
//     creditCardsList,
//     addNewCreditCard,
//     selectedCardId,
//     setSelectedCardId,
//     creditCardErrors,
//     setCreditCardErrors,
//     showSaveBtn,
//     isBillingAddressNeeded,
//   },
// }
 */
const PaymentsOptions = ({ paymentOptions, className }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [value, setValue] = React.useState('credit_card'); // selected payment value
  const [showPaymentsList, setShowPaymentsList] = React.useState(false); // Whether we should show payments list or sepcific payment type view

  const handleChange = (event) => {
    setValue(String(event.target.value));
    setShowPaymentsList(false);
  };

  const handleOnBack = () => {
    ScrollTo('paymentOptions', -100);
    setShowPaymentsList(true);
    switch (value) {
      case 'credit_card':
        if (
          paymentOptions
          && paymentOptions.creditCard
          && paymentOptions.creditCard.selectedCardId
        ) {
          setValue('credit_card');
        } else {
          setValue(null);
        }
        break;
    }
  };

  React.useEffect(() => {
    if (
      paymentOptions
      && paymentOptions.creditCard
      && paymentOptions.creditCard.selectedCardId
    ) {
      setValue('credit_card');
    }
  }, [paymentOptions]);

  return (
    <Root className={`${classes.root} ${className}`} id="paymentOptions">
      {showPaymentsList ? (
        <RadioGroup
          aria-label="payment_method"
          name="payment_method"
          value={value}
          onChange={handleChange}>
          {paymentTypes.map((paymentType) => (
            <FormControlLabel
              key={paymentType.id}
              value={paymentType.key}
              control={
                <Radio color="primary" size={isMobile ? 'small' : 'medium'} />
              }
              onClick={() => handleChange({ target: { value: paymentType.key } })}
              label={(
                <>
                  <Typography className={classes.paymentName}>
                    {paymentType.name}
                  </Typography>
                  <div className={classes.icons}>
                    {paymentType.icons.map((icon, index) => (
                      <React.Fragment key={paymentType.key + index}>
                        {(!isMobile || (isMobile && index < 2)) && (
                          <img
                            src={`/imgs/payments/${icon}`}
                            alt={paymentType.name}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
              classes={{
                label: clsx(classes.labelOption, {
                  [classes.active]: paymentType.key == value,
                }),
              }}
            />
          ))}
        </RadioGroup>
      ) : (
        <>
          <PaymentOption
            type={value}
            onBack={handleOnBack}
            paymentOptions={paymentOptions}
            showPaymentOptions={paymentOptions?.showPaymentOptions}
          />
        </>
      )}
    </Root>
  );
};

export default PaymentsOptions;
