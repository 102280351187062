/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'next-i18next';
import CreditCard from './CreditCard';

const PREFIX = 'PaymentOption';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  body: `${PREFIX}-body`,
  information: `${PREFIX}-information`,
  lockIcon: `${PREFIX}-lockIcon`,
  googleImage: `${PREFIX}-googleImage`,
  sslImage: `${PREFIX}-sslImage`,
  footer: `${PREFIX}-footer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.grey.A000,
    border: `1px solid ${theme.palette.grey[100]}`,
  },

  [`& .${classes.title}`]: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    "& [class*='MuiTypography-root']": {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.grey[800],
    },
  },

  [`& .${classes.body}`]: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },

  [`& .${classes.information}`]: {
    display: 'flex',
    margin: theme.spacing(1, 0, 5),
    "& [class*='MuiTypography-root']": {
      color: theme.palette.grey[300],
      padding: theme.spacing(0, 2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 'normal',
    },
    [`& .${classes.lockIcon}`]: {
      color: theme.palette.grey[300],
    },
  },

  [`& .${classes.lockIcon}`]: {},

  [`& .${classes.googleImage}`]: {
    minWidth: 130,
    width: theme.typography.pxToRem(130),
    height: theme.typography.pxToRem(38),
  },

  [`& .${classes.sslImage}`]: {
    marginLeft: theme.spacing(2),
    minWidth: 74,
    width: theme.typography.pxToRem(74),
    height: theme.typography.pxToRem(38),
  },

  [`& .${classes.footer}`]: {
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    cursor: 'pointer',
    "& [class*='MuiTypography-root']": {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      textDecoration: 'underline',
      marginLeft: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
}));

const PaymentOption = ({
  type,
  onBack,
  paymentOptions,
  showPaymentOptions = true,
}) => {
  const { t } = useTranslation('fe_er_checkout_page');

  const title = {
    credit_card: t('fe_er_checkout_page:credit_or_debit_card'),
    paypal: 'PayPal',
    applepal: 'Apple Pay',
    googlepay: 'Google Pay',
  };

  return (
    <Root className={classes.root}>
      <div className={classes.title}>
        <Typography>{title[type] || ''}</Typography>
      </div>
      <div className={classes.body}>
        <Grid container>
          <Grid className={classes.information} item xs={12} md={7}>
            <LockIcon className={classes.lockIcon} />
            <Typography>
              {t('fe_er_checkout_page:credit_card_secure_form_info')}
            </Typography>
          </Grid>
          {type == 'credit_card' && (
            <Grid item xs={12} md={5}>
              <img
                src="/imgs/payments/google-trusted.jpg"
                alt="google-trusted"
                className={classes.googleImage}
              />
              <img
                src="/imgs/payments/ssl-secure.jpg"
                alt="ssl-secure"
                className={classes.sslImage}
              />
            </Grid>
          )}
        </Grid>
        {type == 'credit_card' && (
          <>
            <CreditCard creditCardOption={paymentOptions.creditCard} />
          </>
        )}
      </div>
      {showPaymentOptions && (
        <div className={classes.footer} onClick={onBack}>
          <ArrowBackIcon />
          <Typography>
            {t('fe_er_checkout_page:show_payment_options')}
          </Typography>
        </div>
      )}
    </Root>
  );
};

export default PaymentOption;
