/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  CircularProgress, Box, Button, Grid, TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import useAddCreditCard from 'views/common/components/Logical/PaymentsOptions/CreditCard/useAddCreditCard';
import BillingAddress from 'views/common/components/Logical/PaymentsOptions/CreditCard/BillingAddress';
import HeadingXS from 'views/common/components/UI/headings/HeadingXS';
import { useTranslation } from 'next-i18next';
import { handleOnWheelEventForInputNumbers } from 'views/common/utils';

const PREFIX = 'CreditCardForm';

const classes = {
  root: `${PREFIX}-root`,
  baseError: `${PREFIX}-baseError`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  cvv: `${PREFIX}-cvv`,
  submit: `${PREFIX}-submit`,
  numberInput: `${PREFIX}-numberInput`,
  errorAlert: `${PREFIX}-errorAlert`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.errorAlert}`]: {
    '&.MuiAlert-root': {
      color: theme.palette.common.white,
    },
  },

  [`& .${classes.baseError}`]: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  [`& .${classes.cvv}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },

  [`& .${classes.submit}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.numberInput}`]: {
    '& input::-webkit-outer-spin-button ,& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const CreditCardForm = ({ onSave, creditCardOption }) => {
  const appState = useSelector((state) => state.globalData.appState);
  const { t } = useTranslation([
    'fe_er_checkout_page',
    'fe_er_billing_address',
  ]);
  const { showSaveBtn = true, isBillingAddressNeeded = false } = creditCardOption;

  const currentUser = appState.current_user;

  const cc = creditCardOption.creditCardFormInfo;
  const ccData = cc.creditCardFormData;
  const { saveCreditCard } = useAddCreditCard();
  const [loading, setLoading] = useState(false);
  const handleExpirationDateChange = (date) => {
    cc.setCreditCardFormData({
      ...ccData,
      expiration_date: date,
    });
  };

  const handleInputChange = (e) => {
    cc.setCreditCardFormData({
      ...ccData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    saveCreditCard(onSave, creditCardOption);
  };

  return (
    <Root className={classes.root}>
      {((!creditCardOption.hideBaseError
        && cc.creditCardFormErrors.base
        && cc.creditCardFormErrors.base.length > 0)
        || cc.creditCardFormErrors.error_message) && (
        <Box mb={4}>
          <Alert
            variant="filled"
            severity="error"
            className={classes.errorAlert}>
            <ul className={classes.baseError}>
              {cc.creditCardFormErrors.error_message && (
                <li>{cc.creditCardFormErrors.error_message}</li>
              )}
              {cc.creditCardFormErrors.base
                && cc.creditCardFormErrors.base.map((err) => (
                  <li key={err}>{err}</li>
                ))}
            </ul>
          </Alert>
        </Box>
      )}
      <Box mb={2.5} mt={3}>
        <HeadingXS
          className={classes.secondaryColor}
          title={t('fe_er_billing_address:credit_card_info')}
        />
      </Box>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="name"
            required
            color="secondary"
            label={t('fe_er_checkout_page:name_on_the_card')}
            variant="outlined"
            name="name"
            type="text"
            value={ccData.name}
            onChange={handleInputChange}
            error={Boolean(cc.creditCardFormErrors.name)}
            helperText={cc.creditCardFormErrors.name}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="number"
            className={classes.numberInput}
            required
            color="secondary"
            label={t('fe_er_checkout_page:card_number')}
            variant="outlined"
            name="number"
            type="number"
            inputProps={{ inputMode: 'numeric' }}
            value={ccData.number}
            onChange={handleInputChange}
            error={Boolean(cc.creditCardFormErrors.number)}
            helperText={cc.creditCardFormErrors.number}
            onWheel={handleOnWheelEventForInputNumbers}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DesktopDatePicker
            required
            fullWidth
            closeOnSelect
            style={{ width: '100%' }}
            disablePast
            name="expiration_date"
            id="expiration_date"
            format="MM/YYYY"
            mask="__/____"
            placeholder="mm/yyyy"
            views={['year', 'month']}
            disableMaskedInput={false}
            value={dayjs(ccData.expiration_date || null) || ''}
            label={t('fe_er_checkout_page:expiration_date')}
            onChange={handleExpirationDateChange}
            slotProps={{
              textField: {
                helperText: `
                ${
                  cc.creditCardFormErrors.expiration_month
                    ? cc.creditCardFormErrors.expiration_month
                    : ''
                }
                ${
                  Boolean(cc.creditCardFormErrors.expiration_month)
                  && Boolean(cc.creditCardFormErrors.expiration_year)
                    ? ','
                    : ''
                }
                ${
                  cc.creditCardFormErrors.expiration_year
                    ? cc.creditCardFormErrors.expiration_year
                    : ''
                }`,
                error:
                  Boolean(cc.creditCardFormErrors.expiration_month)
                  || Boolean(cc.creditCardFormErrors.expiration_year),
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={7}>
          <TextField
            fullWidth
            id="verification_value"
            required
            color="secondary"
            label={t('fe_er_checkout_page:cvv')}
            variant="outlined"
            name="verification_value"
            type="number"
            autoComplete="off"
            value={ccData.verification_value}
            onChange={handleInputChange}
            className={classes.numberInput}
            error={Boolean(cc.creditCardFormErrors.verification_value)}
            helperText={cc.creditCardFormErrors.verification_value}
            onWheel={handleOnWheelEventForInputNumbers}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={5}>
          <div className={classes.cvv}>
            <img src="/imgs/payments/ccv.svg" alt="ccv" />
            <img
              src="/imgs/payments/ccv-american-express.svg"
              alt="ccv-american-express.svg"
            />
          </div>
        </Grid>
      </Grid>

      {isBillingAddressNeeded && (
        <>
          <Box mb={2.5} mt={3}>
            <HeadingXS
              className={classes.secondaryColor}
              title={t('fe_er_billing_address:billing_info')}
            />
          </Box>
          <BillingAddress
            creditCardInfo={cc.creditCardFormData}
            setCreditCardInfo={cc.setCreditCardFormData}
            countries={cc.creditCardFormData && cc.creditCardFormData.countries}
            creditCardError={
              cc.creditCardFormErrors
              && cc.creditCardFormErrors.billing_address && {
                errors: cc.creditCardFormErrors.billing_address,
              }
            }
          />
        </>
      )}

      {showSaveBtn && currentUser && !currentUser.privileged && (
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Box style={{ position: 'relative', display: 'inline-flex' }}>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.submit}
                onClick={handleSubmit}>
                {t('fe_er_checkout_page:save_new_card')}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Root>
  );
};

export default CreditCardForm;
