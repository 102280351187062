import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreditCardList from './CreditCardList';
import CreditCardForm from './CreditCardForm';

const CreditCard = ({ creditCardOption }) => {
  const appState = useSelector((state) => state.globalData.appState);
  const currentUser = appState.current_user;

  const [showList, setShowList] = useState(false); // User credit cards list

  useEffect(() => {
    creditCardOption.creditCardsList.length > 0 && setShowList(true);
  }, [creditCardOption.creditCardsList]);
  return (
    <div>
      {showList
      && currentUser
      && (creditCardOption.showList || !currentUser.privileged) ? (
        <CreditCardList
          onAddNewCard={() => {
            setShowList(false);
            creditCardOption.setSelectedCardId(null);
          }}
          creditCardOption={creditCardOption}
        />
        ) : (
          <CreditCardForm
            creditCardOption={creditCardOption}
            onSave={() => setShowList(true)}
        />
        )}
    </div>
  );
};

export default CreditCard;
