/* eslint-disable consistent-return */
import dayjs from 'dayjs';
import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';

const useAddCreditCard = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  const saveCreditCard = async (
    onSave,
    creditCardOption,
    enableLoading = false,
  ) => {
    // e.preventDefault();
    const cc = creditCardOption.creditCardFormInfo;
    const ccData = cc.creditCardFormData;
    if (ccData.expiration_date == 'Invalid Date') {
      cc.setCreditCardFormErrors({
        ...cc.creditCardFormErrors,
        expiration_year: 'Invalid Date',
      });
      return;
    }
    // cc.setCreditCardFormErrors({});
    !enableLoading && dispatch(setLoading(true));

    try {
      const { data } = await axios.post(API.payments.creditCards, {
        ...ccData,
        authenticity_token: appState.authenticity_token,
        expiration_year: dayjs(ccData.expiration_date).year(),
        expiration_month: dayjs(ccData.expiration_date).month() + 1,
      });
      //  add data to the list of existing CC
      cc.setCreditCardFormErrors({});
      // call onSave function to change the view
      if (data.credit_card) {
        creditCardOption.addNewCreditCard(data.credit_card);
        creditCardOption.setSelectedCardId(String(data.credit_card.id));
        !enableLoading && dispatch(setLoading(false));
        onSave();
      }
      cc.setCreditCardFormData({});
      return data;
    } catch (error) {
      try {
        cc.setCreditCardFormErrors({});
        switch (error.response.status) {
          case 406:
            cc.setCreditCardFormErrors({
              ...error.response.data.errors,
            });
            if (error.response.data.error_message) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error.response.data.error_message,
                }),
              );
            }
            break;

          default:
            handleErrorResponse(error);
            break;
        }
        dispatch(setLoading(false));
      } catch (e) {
        handleErrorResponse(error);
      }
    }
  };
  return { saveCreditCard };
};

export default useAddCreditCard;
